<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #F7F5F9;">

        <div class="contacts-page">

            <BHLoading :show="loadingPage" />
			<div class="mb-2" style="display: inline-block;">
				<div style="display: inline-block; cursor: pointer;">
					<div @click="$router.push('/')" class="dF">
						<a-icon class="mr-4" style="font-size: 1.75rem;" type="arrow-left" />
						<h3>Back to All Packages</h3>
					</div>
				</div>
			</div>
            <h4 class="mb-3 mt-3">{{title}}</h4>

            <div class="contacts-page dF h-full w-full">
                <div class="responsiveFlex w-full">
                    <div class="rightSide" style="order: 2;">
                        <a-card class="mb-4" title="Status and Visibility">
                            <a-select v-model="newBrick.status" style="width:100%;" size="large" >
                                <a-select-option value="draft">Draft</a-select-option>
                                <a-select-option value="published">Published</a-select-option>
                            </a-select>
                            <div class="mt-4 dF aC jE w-full" style="gap: 1.5em;">
                                <div @click="deleteBrick()" v-if="type == 'edit' && $p > 10" class="f1" style="color: var(--orange); text-decoration: underline; cursor: pointer;">
                                    DELETE EXTERIOR COLOR PACKAGE
                                </div>
                                <a-button @click="addBrick" type="primary" size="large">SAVE</a-button>
                            </div>
                        </a-card>
                        <a-card class="mb-4 relative" title="Thumbnail Image">
							<div class="absolute" style="top: 15px; left: 145px; font-size: 16px; color: var(--danger);">*</div>
                            <ImageBox :img="newBrick.thumbnail" @callback="selectThumbnail" @formats="formats"/>
                        </a-card>
                    </div>
                    <div class="leftSide">
                        <a-card title="Title" class="relative">
							<div class="absolute" style="top: 15px; left: 58px; font-size: 16px; color: var(--danger);">*</div>
                            <a-input v-model="newBrick.name" placeholder="Example: Package name" size="large" />
                        </a-card>
                        <a-card class="mt-4" title="Description">
                            <Tiptap v-model="newBrick.description" />
                        </a-card>
                        <a-card class="mt-4 relative" title="Image">
							<div class="absolute" style="top: 15px; left: 69px; font-size: 16px; color: var(--danger);">*</div>
                            <ImageBox :img="newBrick.image" @callback="selectImg" @formats="formats"/>
                        </a-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {formatDate} from 'bh-mod'
    import BHLoading from 'bh-mod/components/common/Loading'
    import ImageBox from '@/components/features/ImageBox'
    import Tiptap from '@/components/TipTap.vue'
    export default {
        components:{
            BHLoading,ImageBox,Tiptap
        },
        data() {
            return {
                title:'',
                newBrick:{
                    name:'',
                    description:'',
                    thumbnail:'',
                    image:'',
                    status:'draft'
                },
                type:'add',

                duplicate:{
                    name:'',
                    id:'',
                    show:false,
                    load:false
                },
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1'
            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
            origBricks(){
                console.log('ORIGINAL BRICKS', Object.values(this.$store.state.features.bricks))
                return Object.values(this.$store.state.features.bricks)
            },
            currentPage:{
                get(){
                    return this.$store.state.features.currentPage
                },
                set(val){
                    this.$store.commit('SET_CONTACTS_PAGE', val)
                }
            },
            newStoreTime(){
                return this.$store.state.features.newStoreTime
            },
            loadingPage:{
                get(){
                    return this.$store.state.features.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            deleteBrick(){
                let self = this
                if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')

                this.$confirm({
                    title: 'Delete Exterior Color Package',
                    content: h => <div>Do you want to delete this Exterior Color Package?</div>,
                    okText: 'DELETE',
                    okType: 'danger',
                    cancelText: 'CANCEL',
                    centered:true,
                    onOk() {
                        console.log('CONFIRM DELETE', self.newBrick)
                        self.$api.delete(`/bricks/:instance/${self.newBrick.id}`).then( ({data}) => {
                            console.log('DATA FROM DELETE API ', data)
                            self.$store.commit('SET_PROP',{where:['bricks', data.id], what:data, del:true})
                            self.$router.push('/')
                            self.$message.success('Exterior Color Package successfully deleted')
                        }).catch(err => {
                            console.error('ERRR', self.$err(err))
                            self.$message.error(self.$err(err))
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            addBrick(){
                if (!this.newBrick.name.trim() || !this.newBrick.thumbnail || !this.newBrick.image) return this.$message.error('Please review and try again.')

                this.$store.commit('LOAD_PAGE',true)

                if (this.type == 'add'){
                    let lastNum = 0
                    this.origBricks.forEach(b => {
                        if (b.order > lastNum) lastNum = b.order
                    })


                    this.newBrick.order = lastNum + 1


                    this.$api.post(`/bricks/${this.$store.state.instance.id}`, this.newBrick).then( ( {data} ) => {

                        this.newBrick = JSON.parse(JSON.stringify(data))
                        this.$store.commit('LOAD_PAGE',false)
                        this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data})

                        console.log('ORIGINAL BRICKS', this.origBricks)

                        this.$message.success('Successfully added Package')
                        this.$router.push(`/editbrick/${data.id}`)
                        setTimeout(() => {
                            this.title = 'Edit Exterior Color Package'
                            this.type = 'edit'
                        },500)

                    }).catch(err => {

                        this.$store.commit('LOAD_PAGE',false)
                        this.$message.error(this.$err(err))

                    })
                } else {
                    console.log('EDITING EXTERIOR COLOR PACKAGE', this.newBrick)

                    this.$api.put(`/bricks/${this.$store.state.instance.id}/${this.newBrick.id}`, this.newBrick).then( ( {data} ) => {
                        this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data})
                        this.$store.commit('LOAD_PAGE',false)
                        this.$message.success('Successfully updated Package')

                    }).catch(err => {

                        this.$store.commit('LOAD_PAGE',false)
                        console.error('ERRR', this.$err(err))
                        this.$message.error(this.$err(err))

                    })
                }



            },
            selectImg(data) {
                // console.log('data', data)
                if(!data.includes('https') && data != '') {
                data = 'https://'+data
                }
                this.newBrick.image = data
                // this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:'images'})
            },
            selectThumbnail(data) {
                // console.log('data', data)
                if(!data.includes('https') && data != '') {
                data = 'https://'+data
                }
                this.newBrick.thumbnail = data
                // this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:'images'})
            },
            formats(e){
                console.log('FORMATS', e)
            },
            formatDate,
            getDeliveredPerc({counts,contacts}){
                let {delivered = 0} = counts
                return (delivered / contacts.length) * 100
            },
            itemMenuClick( { item, key, keyPath },broadcast) {
                console.log('BROADCAST CLICKED',broadcast,key)
                if (key === 'dup'){
                    this.dupTemplate(broadcast.email)
                } else if (key === 'report'){
                    this.$message.error('This functionality is currently not available')
                }
            },
            async handleDuplicate(){

                this.duplicate.load = true

                let {id,name} = this.duplicate
                let {data} = await this.$api.post(`/emails/${this.instance.id}/clone`,{id,name})

                this.$store.commit('SHOW_EDIT_TEMPLATE',data)
                setTimeout(() => {
                    this.duplicate.load = false
                    this.closeDuplicate()
                }, 1000);

                // console.log('COPIED TEMPLATE CLONEDD******* ',data)
            },
            openTemplate(template){
                console.log('TEMPLATE')
                this.$store.commit('SHOW_EDIT_TEMPLATE',template)
            },
            removeSearch({srch,searchKey}){
                console.log('REMOVING', srch)
                console.log('REMOVING', searchKey)
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },

        },
        mounted(){

        },
        created() {
            console.log('sdafklsadjflkasdjl')
            if (this.$route.params.hasOwnProperty('id')){
                this.title = 'Edit Exterior Color Package'
                let index = this.origBricks.findIndex(x => x.id == this.$route.params.id)
                this.newBrick = JSON.parse(JSON.stringify(this.origBricks[index]))
                this.type = 'edit'
            } else {
                this.title = 'Add Exterior Color Package'
                this.type = 'add'
            }

            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);

        }

    }
</script>

<style lang="scss" scoped>
    .image-holder-box{
        display: table;
        width: 100%;
        height: 203px;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
    }
    .image-holder-box:hover{
        border-color: var(--orange);
        transition: border-color .3s ease;
    }
    .image-holder-box .content{
        display: table-cell;
        width: 100%;
        height: 100%;
        padding: 8px;
        text-align: center;
        vertical-align: middle;
    }
    .image-holder-box .content .inside{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        width: 100%;
        height: 203px;
    }
    .image-selector {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height:100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    @media screen and (min-width: 1250px){
        .responsiveFlex {
            display: flex;
            gap: 1.25em;
        }
        .leftSide {
            width: 75%;
        }
        .rightSide {
            width: 25%;
        }
    }
    @media screen and (max-width: 1250px){
        .responsiveFlex {
            display: block;
        }
        .leftSide {
            width: 100%;
        }
        .rightSide {
            width: 100%;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:2%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }



</style>
